import { getAssetUrl } from 'Shared/assets';
// All icons that we want to include in the final sprites.svg
import '../icons/backspace.svg';
import '../icons/bars-solid.svg';
import '../icons/check.svg';
import '../icons/chevron-left.svg';
import '../icons/chevron-right.svg';
import '../icons/clock.svg';
import '../icons/close.svg';
import '../icons/comment.svg';
import '../icons/content-copy.svg';
import '../icons/enter.svg';
import '../icons/eraser.svg';
import '../icons/external-link.svg';
import '../icons/focus.svg';
import '../icons/fullscreen.svg';
import '../icons/gear.svg';
import '../icons/info.svg';
import '../icons/lightbulb.svg';
import '../icons/logout.svg';
import '../icons/pause.svg';
import '../icons/people.svg';
import '../icons/person-add.svg';
import '../icons/print.svg';
import '../icons/question.svg';
import '../icons/restart.svg';
import '../icons/share.svg';
import '../icons/shuffle.svg';
import '../icons/sound-off.svg';
import '../icons/sound-on.svg';
import '../icons/star.svg';
import '../icons/stats.svg';
import '../icons/theme-default.svg';
import '../icons/theme-dark.svg';
import '../icons/theme-light.svg';
import '../icons/trash.svg';
import '../icons/triangle.svg';
import '../icons/undo.svg';
import '../icons/zoom-in.svg';
import '../icons/zoom-out.svg';
// Cinco
import '../icons/cinco/cactus.svg';
import '../icons/cinco/chili.svg';
import '../icons/cinco/cinco-question-mark.svg';
import '../icons/cinco/logo.svg';
import '../icons/cinco/maracas.svg';
import '../icons/cinco/pinata.svg';
import '../icons/cinco/sombrero.svg';
// Lineup
import '../icons/lineup/lock-solid.svg';
import '../icons/lineup/lock-open-solid.svg';
// NRC - This need to end up in the portal sometime
import '../icons/nrc/nrc-article.svg';
import '../icons/nrc/nrc-back.svg';
import '../icons/nrc/nrc-eraser.svg';
import '../icons/nrc/nrc-hide.svg';
import '../icons/nrc/nrc-hint.svg';
import '../icons/nrc/nrc-print.svg';
import '../icons/nrc/nrc-restart.svg';
import '../icons/nrc/nrc-show.svg';
import '../icons/nrc/nrc-shuffle.svg';
import '../icons/nrc/nrc-trash.svg';
import '../icons/nrc/nrc-uitleg.svg';
import '../icons/nrc/nrc-zoom.svg';
// DEV
import '../icons/dev/dev-broom.svg';
import '../icons/dev/dev-cubes.svg';
import '../icons/dev/dev-wizard.svg';

export let svg: string | undefined;

// Load icon sprite map svg and export as svg html string to be used in player components
export async function loadIcons() {
  const response = await fetch(getAssetUrl('img/sprite.svg'));
  svg = await response.text();
}
